import React from "react";
import Layout from "../../components/Layout";
import img1 from "./img/1.png";
import img2 from "./img/2.png";
import img3 from "./img/3.png";
import img4 from "./img/4.png";
import img5 from "./img/5.png";

const Contact = () => {
  const [level, setLevel] = React.useState(0);
  const [state, setState] = React.useState({
    name: "",
    service: "",
    email: "",
    special: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      (level === 0 && state.name) ||
      (level === 1 && state.service) ||
      (level === 2 && state.email) ||
      (level === 3 && state.special)
    ) {
      if (level === 3) {
        // Si estamos en el último nivel, se envía el formulario
        console.log("Datos enviados al webhook:", JSON.stringify(state));
        fetch("https://hook.us1.make.com/y5m1qh979naqpf73toqwx8jmq51zbjnx", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(state),
        })
          .then((response) => {
            console.log("Respuesta del servidor:", response);
            if (!response.ok) {
              throw new Error(`Error en el servidor: ${response.status}`);
            }
            return response.text();
          })
          .then((data) => {
            console.log("Datos recibidos del servidor:", data);
            alert(
              "¡Gracias por contactarnos! En un lapso de 24hrs uno de nuestros especialistas se pondrá en contacto contigo."
            );
          })
          .catch((error) => {
            console.error("Error al enviar la solicitud:", error);
            alert(
              `Hubo un problema enviando tu información. Error: ${error.message}. Inténtalo nuevamente.`
            );
          });
      } else {
        setLevel(level + 1); // Avanza al siguiente paso del formulario
      }
    } else {
      alert("Por favor, completa el campo antes de continuar.");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      // Si estamos en el último nivel (nivel 3), llamamos a handleSubmit
      if (level === 3) {
        handleSubmit(e); // Llamamos a la función de envío del formulario
      } else {
        // Avanzamos al siguiente nivel si no estamos en el nivel 3
        if (
          (level === 0 && state.name) ||
          (level === 1 && state.service) ||
          (level === 2 && state.email) ||
          (level === 3 && state.special)
        ) {
          setLevel(level + 1);
        } else {
          alert("Por favor, completa el campo antes de continuar.");
        }
      }
    }
  };

  return (
    <Layout>
      <section
        className="pb-[200px] pt-[100px] md:pt-[128px] md:pb-[333px] xl:pt-[278px] xl:pb-[533px] relative overflow-hidden"
        style={{
          background: `url('/img/contact/contact-bg.png') no-repeat center center / cover`,
        }}
      >
        <div className="container relative z-10">
          <div className="">
            <>
              <h1 className="font-bold text-2xl md:text-4xl lg:text-6xl xl:text-[64px] xl:leading-[1.5] mb-5">
                {level === 0
                  ? "¿Cuál es tu nombre?"
                  : level === 1
                  ? "¿Qué servicio te interesa?"
                  : level === 2
                  ? "¿Cuál es tu correo?"
                  : level === 3
                  ? "¿Algún requerimiento especial?"
                  : "¡Gracias por contactarnos!"}
              </h1>
              <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
                {level === 0 ? (
                  <input
                    type="text"
                    className="form-input form-input-2"
                    placeholder="Ingresa tu nombre completo"
                    value={state.name}
                    onChange={(e) =>
                      setState({ ...state, name: e.target.value })
                    }
                    required
                  />
                ) : level === 1 ? (
                  <select
                    className="form-input form-input-2"
                    value={state.service}
                    onChange={(e) =>
                      setState({ ...state, service: e.target.value })
                    }
                    required
                  >
                    <option value="">Seleccionar servicio</option>
                    <option value="Muros">Muros</option>
                    <option value="Autobuses">Autobuses</option>
                    <option value="Vallas">Vallas</option>
                    <option value="Espectaculares">Espectaculares</option>
                    <option value="Puentes peatonales">
                      Puentes peatonales
                    </option>
                    <option value="Pantallas digitales">
                      Pantallas digitales
                    </option>
                    <option value="Aeropuertos">Aeropuertos</option>
                    <option value="Centros comerciales">
                      Centros comerciales
                    </option>
                  </select>
                ) : level === 2 ? (
                  <input
                    type="email"
                    className="form-input form-input-2"
                    placeholder="Ingresa tu correo"
                    value={state.email}
                    onChange={(e) =>
                      setState({ ...state, email: e.target.value })
                    }
                    required
                  />
                ) : level === 3 ? (
                  <input
                    type="text"
                    className="form-input form-input-2"
                    placeholder="Ingresa tu requerimiento especial"
                    value={state.special}
                    onChange={(e) =>
                      setState({ ...state, special: e.target.value })}
                    required
                  />
                ) : (
                  <p className="text-md md:text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl lg:max-w-[870px] 2xl:max-w-[1072px]">
                    En un lapso de 24hrs uno de nuestros especialistas se pondrá
                    en contacto contigo.
                  </p>
                )}
              </form>
              <style jsx>{`
                select.form-input-2 {
                  padding: 8px;
                  height: auto;
                  line-height: 1.5;
                  font-size: 16px;
                }

                input.form-input-2 {
                  padding: 8px;
                  height: auto;
                  line-height: 1.5;
                  font-size: 16px;
                }
              `}</style>
            </>
          </div>
        </div>
        <img
          src={
            level === 0
              ? img1
              : level === 1
              ? img2
              : level === 2
              ? img3
              : level === 3
              ? img4
              : img5
          }
          className="absolute left-[31px] bottom-12 sm:bottom-12 xl:bottom-[102px] max-xl:w-[calc(100vw-62px)] 2xl:w-[unset] md:w-[70vw] z-10"
          alt=""
        />
        <img
          src="/img/contact/right.png"
          className="absolute right-0 bottom-[85px] max-xl:w-[300px] max-xl:bottom-0 max-md:hidden"
          alt=""
        />
      </section>
    </Layout>
  );
};

export default Contact;
